
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { mapState } from "vuex";

const languages = [
  { value: "fi", text: "Suomeksi" },
  { value: "en", text: "English" },
];

export default defineComponent({
  name: "HelloI18n",
  computed: {
    ...mapState(["language"]),
    otherLanguage(): Array<{ value: string; text: string }> {
      return languages.filter((l) => l.value !== this.language);
    },
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    const currentRoute = computed(() => {
      return useRoute().name;
    });
    return { t, currentRoute };
  },
});
