import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createMetaManager } from "vue-meta";
import i18n from "@/i18n";
import FontAwesomeIcon from "@/utilities/fontawesome-icons";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap";

const metaManager = createMetaManager();

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(i18n)
  .use(store)
  .use(router)
  .use(metaManager)
  .mount("#app");
