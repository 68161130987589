import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherLanguage, (lang) => {
        return (_openBlock(), _createBlock(_component_RouterLink, {
          key: lang.value,
          to: { params: { locale: lang.value }, name: _ctx.currentRoute }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(lang.text), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.t("hello")), 1)
  ], 64))
}