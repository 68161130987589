import setLanguage from "@/store/mutations/setLanguage";
import addPoints from "@/store/mutations/addPoints";
import setWinner from "@/store/mutations/setWinner";
import resetState from "@/store/mutations/resetState";

export default {
  ...setLanguage,
  ...addPoints,
  ...setWinner,
  ...resetState,
};
