import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0613de01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "share-icons" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      target: "_blank",
      href: 'https://www.facebook.com/sharer.php?u=' + _ctx.shareUrl
    }, [
      _createVNode(_component_font_awesome_icon, { icon: ['fab', 'facebook-f'] })
    ], 8, _hoisted_2),
    _createElementVNode("a", {
      target: "_blank",
      href: 'https://twitter.com/share?url=' + _ctx.shareUrl
    }, [
      _createVNode(_component_font_awesome_icon, { icon: ['fab', 'twitter'] })
    ], 8, _hoisted_3),
    _createElementVNode("a", {
      target: "_blank",
      href: 'https://www.linkedin.com/sharing/share-offsite/?url=' + _ctx.shareUrl
    }, [
      _createVNode(_component_font_awesome_icon, { icon: ['fab', 'linkedin'] })
    ], 8, _hoisted_4),
    _createElementVNode("a", {
      target: "_blank",
      href: 
        'https://api.whatsapp.com/send?text=' + _ctx.shareUrl + ' | ' + _ctx.shareText
      
    }, [
      _createVNode(_component_font_awesome_icon, { icon: ['fab', 'whatsapp'] })
    ], 8, _hoisted_5)
  ]))
}