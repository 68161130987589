export default {
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start test"])},
  "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redo the test"])},
  "share_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share test"])},
  "home_share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you suited for delivery work? Take this test and find out!"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["are-you-suited-for-delivery-work.svg"])},
  "take_test_in_other_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tee testi suomeksi"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mass i18n !!"])},
  "hare": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for participating. You are a SPORTY HARE, and you would be a great delivery worker."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are as fast as a hare and enjoy moving from one place to another. Delivery work is basically paid incidental activity, and night work allows you to engage in other activities during the day."])},
    "share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m a sporty hare and suited for delivering newspapers and other mail at night and early morning. How about you?"])}
  },
  "lynx": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for participating. You are a FLEXIBLE LYNX, and you would be a great delivery worker."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are as agile as a lynx and adapt well to changing situations. You enjoy varied work, and part-time night work is a good fit for your life situation and daily rhythm."])},
    "share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m a flexible lynx and suited for delivering newspapers and other mail at night and early morning. How about you?"])}
  },
  "owl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for participating. You are a CONSCIENTIOUS OWL, and you would be a great delivery worker."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are as precise as an owl and perform your work conscientiously. You are quick to take on new working methods and routes, and the papers you deliver always reach the right address. As a deliverer your work is highly valued."])},
    "share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m a conscientious owl and suited for delivering newspapers and other mail at night and early morning. How about you?"])}
  },
  "wolf": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for participating. You are an INDEPENDENT WOLF, and you would be a great delivery worker."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are as independent as a wolf and love your peace. Delivery work offers independence and a peaceful working environment. Your work is already done when others are only waking up to their jobs."])},
    "share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I’m an independent wolf and suited for delivering newspapers and other mail at night and early morning. How about you?"])}
  },
  "share_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHARE YOUR RESULT ON SOCIAL MEDIA!"])},
  "result_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about working as a deliverer and check out the companies offering delivery work in your area at"])},
  "result_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.varhaisjakajaksi.fi"])},
  "finnmedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnmedia"])},
  "finnmedia_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.medialiitto.fi/finnmedia/"])},
  "industrial_union": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial Union"])},
  "industrial_union_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.teollisuusliitto.fi/en/"])},
  "test_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test by"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])}
}