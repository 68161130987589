
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";
import { Answer } from "@/store/defaultState";
import { MutationTypes } from "@/store/mutations/types";
import { maxBy } from "lodash";
export default defineComponent({
  name: "Test",
  data(): {
    question: number;
    letters: Array<string>;
  } {
    return {
      question: 0,
      letters: ["A", "B", "C", "D"],
    };
  },
  computed: {
    ...mapState(["language", "questions", "result"]),
  },
  methods: {
    ...mapMutations({
      addPoints: MutationTypes.ADD_POINTS,
      setWinner: MutationTypes.SET_WINNER,
    }),
    answerSelect(answer: Answer) {
      this.addPoints({
        animal: answer.animal,
        points: answer.points,
      });

      if (this.questions.length > this.question + 1) {
        this.question++;
      } else {
        this.goToResult();
      }
    },
    questionProgression(index: number) {
      return this.question >= index;
    },
    goToResult() {
      const winner = maxBy(Object.keys(this.result), (o) => this.result[o]);

      if (winner) {
        const lowercase = winner.toLowerCase();
        this.setWinner(lowercase);
        this.$router.push({
          name: "Result",
          params: { result: lowercase },
        });
      }
    },
  },
});
