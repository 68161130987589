import questions from "@/data/questions.json";

//Questions
export interface Questions {
  question: Question;
  answers: Answer[];
}

export interface Answer {
  fi: string;
  en: string;
  points: number;
  animal: Animal;
}

export enum Animal {
  Hare = "Hare",
  Lynx = "Lynx",
  Owl = "Owl",
  Wolf = "Wolf",
}

export interface Question {
  fi: string;
  en: string;
}

// Meta
export interface Meta {
  fi: metaFields;
  en: metaFields;
}
export interface metaFields {
  title: string;
  description: string;
  image: string;
  hare: resultMetaFields;
  lynx: resultMetaFields;
  owl: resultMetaFields;
  wolf: resultMetaFields;
}
export interface resultMetaFields {
  image: string;
  description: string;
}

// Test
export interface Test {
  Hare: testFields;
  Lynx: testFields;
  Owl: testFields;
  Wolf: testFields;
}
export interface testFields {
  points: number;
  amount: number;
  final: number;
  coefficient: number;
}
export interface Result {
  Hare: number;
  Lynx: number;
  Owl: number;
  Wolf: number;
}

// State data types.
export interface State {
  language: string;
  meta: Meta;
  questions: Array<Questions>;
  test: Test;
  result: Result;
  winner: string;
}

export const defaultState = (): State => {
  return <State>{
    language: "fi",
    meta: {
      fi: {
        title: "Millainen jakaja olisit? Tee testi ja pian tiedät!",
        description:
          "Olisiko sinusta jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla?",
        image: "img/images/jakokuva.jpg",
        hare: {
          image: "img/images/jakokuva-hare-fi.jpg",
          description:
            "Olen sporttinen jänis, ja sopisin hyvin jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla. Entä sinä?",
        },
        lynx: {
          image: "img/images/jakokuva-lynx-fi.jpg",
          description:
            "Olen joustava ilves, ja sopisin hyvin jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla. Entä sinä?",
        },
        owl: {
          image: "img/images/jakokuva-owl-fi.jpg",
          description:
            "Olen tunnollinen pöllö, ja sopisin hyvin jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla. Entä sinä?",
        },
        wolf: {
          image: "img/images/jakokuva-wolf-fi.jpg",
          description:
            "Olen itsenäinen susi, ja sopisin hyvin jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla. Entä sinä?",
        },
      },
      en: {
        title: "Are you suited for delivery work? Take this test and find out!",
        description:
          "Would delivering newspapers and other mail at night and early morning suit you?",
        image: "img/images/jakokuva-en.jpg",
        hare: {
          image: "img/images/jakokuva-hare-en.jpg",
          description:
            "I’m a sporty hare and suited for delivering newspapers and other mail at night and early morning. How about you?",
        },
        lynx: {
          image: "img/images/jakokuva-lynx-en.jpg",
          description:
            "I’m a flexible lynx and suited for delivering newspapers and other mail at night and early morning. How about you?",
        },
        owl: {
          image: "img/images/jakokuva-owl-en.jpg",
          description:
            "I’m a conscientious owl and suited for delivering newspapers and other mail at night and early morning. How about you?",
        },
        wolf: {
          image: "img/images/jakokuva-wolf-en.jpg",
          description:
            "I’m an independent wolf and suited for delivering newspapers and other mail at night and early morning. How about you?",
        },
      },
    },
    questions: questions,
    test: {
      Hare: {
        points: 0,
        amount: 0,
        final: 0,
        coefficient: 0,
      },
      Lynx: {
        points: 0,
        amount: 0,
        final: 0,
        coefficient: 0,
      },
      Owl: {
        points: 0,
        amount: 0,
        final: 0,
        coefficient: 0,
      },
      Wolf: {
        points: 0,
        amount: 0,
        final: 0,
        coefficient: 0,
      },
    },
    result: {
      Hare: 0,
      Lynx: 0,
      Owl: 0,
      Wolf: 0,
    },
    winner: "",
  };
};
