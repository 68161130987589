import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import i18n from "@/i18n";
import About from "@/views/About.vue";
import Test from "@/views/Test.vue";
import Result from "@/views/Result.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:locale",
    name: "Home",
    component: Home,
  },
  {
    path: "/:locale/about",
    name: "About",
    component: About,
  },
  {
    path: "/:locale/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/:locale/test/:result/",
    name: "Result",
    component: Result,
    beforeEnter(to, from, next) {
      const locale = to.params.locale;
      const result = to.params.result;
      const allowedAnimals = process.env.VUE_APP_TEST_ANIMALS.split(",");
      if (result && !allowedAnimals.includes(result))
        return next({ name: "Home", params: { locale: locale } });
      if (store.state.winner !== result) {
        store.commit("SET_WINNER", result);
      }
      return next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const locale = to.params.locale;
  const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
  if (!supportedLocales.includes(locale)) return next("fi");
  if (store.state.language !== locale) {
    if (typeof locale === "string") {
      store.commit("SET_LANGUAGE", locale);
      i18n.global.locale.value = locale;
    }
  }
  return next();
});

export default router;
