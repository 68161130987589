
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Share from "@/components/Share.vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "Home",
  components: { Share },
  data(): {
    shareKey: number;
  } {
    return {
      shareKey: 0,
    };
  },
  computed: {
    ...mapState(["language"]),
    otherLanguage(): string {
      return this.language === "fi" ? "en" : "fi";
    },
  },
  methods: {
    startTest() {
      this.$router.push({ name: "Test" });
    },
    reRenderShare() {
      setTimeout(() => {
        this.shareKey += 1;
      }, 100);
    },
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    return { t };
  },
});
