export default {
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloita testi"])},
  "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tee testi uudelleen"])},
  "share_test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaa testi"])},
  "home_share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millainen jakaja olisit? Tee testi ja pian tiedät!"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["millainen-jakaja-olisit.svg"])},
  "take_test_in_other_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take the test in English"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hei i18n !!"])},
  "hare": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos osallistumisestasi. Olet SPORTTINEN JÄNIS, ja sopisit hyvin jakajaksi."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet nopea kuin jänis ja tykkäät liikkua paikasta toiseen. Jakajan työ tarjoaa palkallista hyötyliikuntaa, ja yötyö mahdollistaa sen, että ehdit päivisin harrastaa muita asioita."])},
    "share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen sporttinen jänis, ja sopisin hyvin jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla. Entä sinä?"])}
  },
  "lynx": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos osallistumisestasi. Olet JOUSTAVA ILVES, ja sopisit hyvin jakajaksi."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet ketterä kuin ilves ja joustat tilanteen mukaan. Pidät monipuolisesta työstä, ja osa-aikainen yötyö sopii elämäntilanteeseesi ja päivärytmiisi."])},
    "share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen joustava ilves, ja sopisin hyvin jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla. Entä sinä?"])}
  },
  "owl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos osallistumisestasi. Olet TUNNOLLINEN PÖLLÖ, ja sopisit hyvin jakajaksi."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet tarkka kuin pöllö ja teet työsi tunnollisesti. Omaksut nopeasti uudet työtavat ja reitit, ja jakamasi lehdet löytävät aina oikean osoitteen. Jakajana teet korkealle arvostettua työtä."])},
    "share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen tunnollinen pöllö, ja sopisin hyvin jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla. Entä sinä?"])}
  },
  "wolf": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiitos osallistumisestasi. Olet ITSENÄINEN SUSI, ja sopisit hyvin jakajaksi."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olet itsenäinen kuin susi ja rakastat omaa rauhaa. Jakajan työ tarjoaa itsenäisyyttä ja rauhallisen työympäristön. Työsi on jo tehty, kun muut vasta heräilevät töihin."])},
    "share_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen itsenäinen susi, ja sopisin hyvin jakamaan lehtiä ja muuta postia yöllä ja varhain aamulla. Entä sinä?"])}
  },
  "share_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JAA TULOKSESI SOMESSA!"])},
  "result_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää jakajan työstä ja tutustu omalla alueellasi jakajan työtä tarjoaviin yrityksiin osoitteessa"])},
  "result_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.varhaisjakajaksi.fi"])},
  "finnmedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medialiitto"])},
  "finnmedia_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://medialiitto.fi"])},
  "industrial_union": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teollisuusliitto"])},
  "industrial_union_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://teollisuusliitto.fi"])},
  "test_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testin ovat toteuttaneet"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ja"])}
}