import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "language-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Share = _resolveComponent("Share")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "logo",
        src: require('@/assets/logos/' + _ctx.t('logo')),
        alt: ""
      }, null, 8, _hoisted_3),
      _createElementVNode("button", {
        class: "start",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startTest && _ctx.startTest(...args)))
      }, _toDisplayString(_ctx.t("start")), 1),
      _createElementVNode("h2", null, _toDisplayString(_ctx.t("share_test")), 1),
      (_openBlock(), _createBlock(_component_Share, {
        "share-text": _ctx.t('home_share_text'),
        key: _ctx.shareKey
      }, null, 8, ["share-text"])),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_RouterLink, {
          onClick: _ctx.reRenderShare,
          to: { params: { locale: _ctx.otherLanguage } }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("take_test_in_other_language")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "to"]),
        _createVNode(_component_font_awesome_icon, {
          class: "icon-right",
          icon: ['fas', 'arrow-right']
        })
      ])
    ])
  ]))
}