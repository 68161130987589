
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const allowedLanguages = ["fi", "en"];
    let lang = "fi";
    let getLang = window.location.pathname.split("/")[1];
    if (getLang && allowedLanguages.includes(getLang)) {
      lang = getLang;
    }
    const path = process.env.VUE_APP_BASE_PATH;

    useMeta({
      title: store.state.meta[lang].title,
      og: {
        title: store.state.meta[lang].title,
        type: "website",
        url: path + "/" + lang + "/",
        site_name: store.state.meta[lang].title,
        image: path + "/" + store.state.meta[lang].image,
        description: store.state.meta[lang].description,
      },
      twitter: {
        card: "summary_large_image",
        title: store.state.meta[lang].title,
        description: store.state.meta[lang].description,
        image: path + "/" + store.state.meta[lang].image,
      },
    });
  },
});
