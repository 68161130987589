import { MutationTypes } from "@/store/mutations/types";
import { State, Animal } from "@/store/defaultState";

interface Payload {
  animal: string;
  points: number;
}

export default {
  [MutationTypes.ADD_POINTS](state: State, payload: Payload) {
    const animal = state.test[payload.animal as Animal];
    animal.points = animal.points + payload.points;
    animal.amount++;

    // Result calculation.
    animal.coefficient = animal.amount * 0.25 + 1;
    animal.final = animal.points * animal.coefficient;
    state.result[payload.animal as Animal] = animal.final;
  },
};
