
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import HelloI18n from "@/components/HelloI18n.vue";

export default defineComponent({
  components: { HelloI18n },
  setup() {
    useMeta({
      title: "About: Varhaisjakajaksi",
      og: {
        title: "About: OG Varhaisjakajaksi",
      },
    });
  },
});
