
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "Share",
  props: {
    shareText: String,
  },
  computed: {
    ...mapState(["language"]),
    share() {
      return window.location.origin;
    },
    shareUrl() {
      return window.location.href;
    },
  },
  methods: {
    sharePath() {
      return window.location.pathname;
    },
  },
});
