<template>
  <div class="result">
    <div class="content container">
      <div class="row">
        <div class="col-lg-7 left-column">
          <h1>{{ t($route.params.result + ".title") }}</h1>
          <p>{{ t($route.params.result + ".description") }}</p>
          <img
            class="animal d-lg-none"
            :src="
              require('@/assets/animals/medialiitto-hahmot-' +
                $route.params.result +
                '.svg')
            "
            alt=""
          />
          <h2 class="share-title">{{ t("share_result") }}</h2>
          <Share :share-text="t($route.params.result + '.share_text')" />
          <button class="restart" @click="restartTest">
            {{ t("restart") }}
          </button>
        </div>
        <div class="col-lg-5 animal-container d-none d-lg-flex">
          <img
            class="animal"
            :src="
              require('@/assets/animals/medialiitto-hahmot-' +
                $route.params.result +
                '.svg')
            "
            alt=""
          />
        </div>
        <div class="col-lg-7">
          <p class="result-text">{{ t("result_text") }}</p>
          <a class="mb-1" :href="t('result_link')" target="_blank">{{
            t("result_link")
          }}</a>
        </div>
        <div class="col-lg-5">
          <div class="row">
            <div class="col-lg-5">
              <img
                class="logo"
                :src="require('@/assets/logos/' + t('logo'))"
                alt=""
              />
            </div>
            <div class="col-lg-7 test-by">
              <p>{{ t("test_by") }}</p>
              <p>
                <a :href="t('finnmedia_link')" target="_blank">{{
                  t("finnmedia")
                }}</a>
                {{ t("and") }}
                <a :href="t('industrial_union_link')" target="_blank">{{
                  t("industrial_union")
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Share from "@/components/Share.vue";
import { mapMutations, useStore } from "vuex";
import { MutationTypes } from "@/store/mutations/types";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Result",
  components: { Share },
  methods: {
    ...mapMutations({
      resetState: MutationTypes.RESET_STATE
    }),
    restartTest() {
      this.resetState()
      this.$router.push({name: "Home"})
    }
  },
  setup() {
    const store = useStore();
    const winner = store.state.winner;
    const lang = store.state.language;
    const basePath = process.env.VUE_APP_BASE_PATH;
    const sharePath = window.location.pathname;

    useMeta({
      og: {
        url: basePath + sharePath,
        image: basePath + "/" + store.state.meta[lang][winner].image,
        description: store.state.meta[lang][winner].description
      },
      twitter: {
        image: basePath + "/" + store.state.meta[lang][winner].image,
        description: store.state.meta[lang][winner].description
      }
    })

    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });


    return { t };
  },
});
</script>

<style lang="scss" scoped>
@import "bootstrap";
.result {
  min-height: 100vh;
  background-image: url("~@/assets/backgrounds/tausta-varhaisjakajaksi.jpg");
  background-image: image-set(
    url("~@/assets/backgrounds/tausta-varhaisjakajaksi.webp") type("image/webp"),
    url("~@/assets/backgrounds/tausta-varhaisjakajaksi.jpg") type("image/jpeg")
  );
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  background-size: cover;
  background-position: bottom center;
  overflow-x: hidden;
  @media only screen and (max-width: 768px) {
    justify-content: flex-end;
    background-image: url("~@/assets/backgrounds/tausta-mobiili-1.jpg");
    background-image: image-set(
      url("~@/assets/backgrounds/tausta-mobiili-1.webp") type("image/webp"),
      url("~@/assets/backgrounds/tausta-mobiili-1.jpg") type("image/jpeg")
    );
    background-size: contain;
    background-position: top;
  }

  .content {
    padding: 2.75rem 2.125rem 3.25rem 3.875rem;
    margin: 0 auto;
    width: 100%;
    background: var(--color-primary);
    border: 15px solid #0c4d58a1;
    border-radius: 0 70px 0 0;
    background-clip: padding-box;
    text-align: left;

    .left-column * {
      max-width: 600px;
    }

    h1,
    h2 {
      font-family: var(--font-lexend);
      font-weight: 600;
    }
    h1 {
      font-size: 1.875rem;
    }
    h2.share-title {
      font-size: 1.25rem;
      margin-top: 2.75rem;
    }
    p,
    a {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    a {
      color: var(--color);
    }

    .restart {
      margin-top: 3.125rem;
      margin-bottom: 2.75rem;
    }

    .animal-container {
      display: flex;
      align-items: center;
      img.animal {
        max-width: 100%;
      }
    }

    .result-text {
      margin-top: 0;
      margin-bottom: 0;
      max-width: 470px;
    }
    .test-by {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      p {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    @include media-breakpoint-down(lg) {
      text-align: center;
      margin-top: 250px;
      margin-bottom: 100px;
      padding: 2.75rem 3.875rem 3.25rem 3.875rem;
      .result-text {
        max-width: unset;
      }
      .logo {
        max-width: 168px;
        margin-top: 4.625rem;
        margin-bottom: 2rem;
      }
    }
    @include media-breakpoint-down(sm) {
      h1 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }
      text-align: center;
      padding: 40px 36px 68px 36px;
      margin-bottom: 0;
      border-left: 0;
      border-bottom: 0;
      margin-right: -15px;
      width: calc(100% + 15px);
    }
  }
}
</style>
