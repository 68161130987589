import { createStore, MutationPayload } from "vuex";
import throttle from "lodash.throttle";
import { defaultState } from "@/store/defaultState";
import mutations from "@/store/mutations";
import getters from "@/store/getters";
import { i18n } from "@/i18n";

const state = defaultState();

const store = createStore({
  state,
  mutations: mutations,
  getters: getters,
  actions: {},
  modules: {},
});

// Subscribe to store updates, update at most once every second.
store.subscribe(
  throttle((mutation: MutationPayload, state: Record<string, any>) => {
    if (mutation.type === "SET_LANGUAGE") {
      if (state.language) {
        i18n.global.locale.value = state.language;
        document.documentElement.setAttribute("lang", state.language);
      }
    }
  }, 1000)
);

export default store;
